import { NavLink } from "react-router-dom";
import footer_bg from "../../src/assets/images/gallery/footer_bg.png";
import logo from "../assets/images/logo/mainLogo.png";


const Footer = () => {
  return (
    <>
      <footer>
        {/* <!--? Footer Start--> */}
        <div
          className="footer-area section-bg"
          //   data-background={footer_bg}
        >
          <div className="container">
            <div className="footer-top footer-padding">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    {/* <!-- logo --> */}
                    <div className="footer-logo">
                      <NavLink to="/" className="d-flex align-items-center ">
                        <img src={logo} alt="" className="img-fluid logo" />
                        <h6 className="mb-0 ml-3 header-text">INDULGENCE</h6>

                      </NavLink>{" "}
                    </div>
                    <div className="footer-tittle">
                      <div className="footer-pera">
                        <p className="info1">
                          Our Style Makes Your Look Elegance.
                        </p>
                      </div>
                    </div>
                    <div className="footer-number">
                      <h4>
                        <span>020 </span>8900 1612
                      </h4>
                      <a href="mailto:indulgencenailbeauty@gmail.com" target="_blank">
                      <p>indulgencenailbeauty@gmail.com</p></a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Location </h4>
                      <div className="footer-pera">
                        <p className="info1">
                        532 High Rd, Wembley, United Kingdom, HA9 7BS
                        </p>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Explore</h4>
                      <ul>
                        
                        <li>
                          <NavLink to="/about">About</NavLink>
                        </li>
                        <li>
                          <NavLink to="/service">Service</NavLink>
                        </li>
                        <li>
                          <NavLink to="/portfolio">Portfolio</NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact">Contact</NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Get Updates</h4>
                      <div className="footer-pera">
                        <p className="info1">
                          Subscribe now to get daily updates
                        </p>
                      </div>
                    </div>
                    {/* <!-- Form --> */}
                    <div className="footer-form">
                      <div id="mc_embed_signup">
                        <form
                          target="_blank"
                          action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                          method="get"
                          className="subscribe_form relative mail_part"
                          noValidate="true"
                        >
                          <input
                            type="email"
                            name="EMAIL"
                            id="newsletter-form-email"
                            placeholder=" Email Address "
                            className="placeholder hide-on-focus"
                          />
                          <div className="form-icon">
                            <button
                              type="submit"
                              name="submit"
                              id="newsletter-submit"
                              className="email_icon newsletter-submit button-contactForm"
                            >
                              Send
                            </button>
                          </div>
                          <div className="mt-10 info"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row d-flex justify-content-between align-items-center">
                <div className="col-xl-9 col-lg-8">
                  <div className="footer-copy-right">
                    {/* <p><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
  Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>
  <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --></p> */}
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4">
                  {/* <!-- Footer Social --> */}
                  <div className="footer-social f-right">
                    <a href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="">
                      <i className="fas fa-globe"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Footer End--> */}
      </footer>
      {/* <!-- Scroll Up --> */}
      <div id="back-top">
        <a title="Go to Top" href="#">
          {" "}
          <i className="fas fa-level-up-alt"></i>
        </a>
      </div>
    </>
  );
};

export default Footer;
