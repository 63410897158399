import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { LoginFormValidation } from "../../config/setting";
import { LOGIN } from "../../config/apiUrl";

const Login = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setErrors] = useState({});
  const navigat = useNavigate();

  const handleChange = (value, name) => {
    setUserData({ ...userData, [name]: value });
  };

  const Login = async () => {
    document.title = "Login | Indulgence";
    const { errors, isError } = LoginFormValidation(userData);
    setErrors(errors);
    if (!isError) {
      try {
        setLoader(true);

        const response = await axiosInstance.post(LOGIN, userData);
        if (response) {
          setLoader(false);
          const { data, token } = response.data.payload;
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("userData", JSON.stringify(data));
          toast.success(response.data.message);
          setUserData({});
          navigat("/");
        }
      } catch (error) {
        if(error.response){
        setLoader(false);

        toast.error(error.response.data.message);
        }
      }
    }
  };

  return (
    <>
      <div className="slider-area3">
        <div className="background-overlay-area3"></div>
        <div className="slider-height3 d-flex align-items-center">
          <div className="container ">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-8">
                <div className="form-main-div">
                  <div className="row">
                    <div className="col-xl-12 px-0 mb-5">
                      <div className="hero-cap hero-cap2 text-center">
                        <h2>Login</h2>
                      </div>
                    </div>
                    <div className="col-xl-12 px-0">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Email"
                          autoComplete="off"
                          value={userData?.email || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "email")
                          }
                        />
                        <p className="red-color">{error?.email}</p>
                      </div>
                    </div>

                    <div className="col-xl-12 px-0">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlpass"
                          placeholder="Enter Password"
                          autoComplete="off"
                          value={userData?.password || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "password")
                          }
                        />
                        <p className="red-color">{error?.password}</p>
                      </div>
                    </div>

                    <div className="col-xl-12 d-flex justify-content-center mb-4">
                      <div
                        className="btn header-btn"
                        type="button"
                        onClick={() => Login()}
                      >
                        {loader == true ? (
                          <>
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                          </>
                        ) : (
                          "Submit"
                        )}
                      </div>
                    </div>

                    <div className="col-xl-12 d-flex flex-column justify-content-center align-items-center">
                      <div className="ac-login-txt mb-4 text-center">
                        Don't have an account ?{" "}
                        <NavLink className="inner-txt" to="/signup">
                          Signup
                        </NavLink>
                      </div>
                      <NavLink to="/forgot-password">
                        Forgot Password ?{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
