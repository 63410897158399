import { useEffect, useState } from "react";
import pricing1 from "../assets/images/gallery/pricing1.png";
import pricing2 from "../assets/images/pricing-new.png";
import { GET_SERVICE } from "../config/apiUrl";
import axios from "../config/axiosInstance";

const Pricing = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [service, setService] = useState({});

  useEffect(() => {
    // Function to check screen size
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check screen size on initial render
    checkScreenSize();

    // Add event listener to update on window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  },[]);

  

  const getService = async () => {
    try {
      const response = await axios.get(GET_SERVICE);
      if (response) {
        setService(response?.data?.payload);
      }
    } catch (error) {
      setService([]);
    }
  };

  useEffect(() => {
    getService();
  }, []);
  return (
    <>
      <div className="best-pricing section-padding2 position-relative">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-sm-12 col-12 pl-0"
            
            >
                <div className="pricing-img-div"> 
                <img className="pricing-img1" src={pricing1} alt="" />
                <img className="pricing-img2" src={pricing2} alt="" />

                </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-sm-12 col-12"
              // data-aos={!isMobile ? "fade-left" : ""}
              // data-aos-delay={!isMobile ? "300" : ""}
            >
              <div className="section-tittle mb-50">
                <span className="best-pricing">Our Best Pricing</span>
                <h2 className="provide-price">
                  We provide best price
                  <br /> in the city!
                </h2>
              </div>
              {/* <!-- Pricing  --> */}
              {service && service.length > 0 && (
                <div className="row">
                  {service.map(
                    (item, index) =>
                      item.show_in_menu === true && (
                        <div
                          className="col-lg-6 col-md-6 col-sm-12 col-12"
                          key={index}
                        >
                          <div className="pricing-list">
                            <ul>
                              <li>
                                {item?.service_name}. . . . . . . . . . . . . .
                                . . . . . . .{" "}
                                <span>£ {item?.service_price}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <!-- pricing img --> */}
        {/* <div className="pricing-img">
          <img className="pricing-img1" src={pricing1} alt="" />
          <img className="pricing-img2" src={pricing2} alt="" />
        </div> */}
      </div>
    </>
  );
};

export default Pricing;
