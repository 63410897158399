import about from "../assets/images/about-home.png";
import signature from "../assets/images/gallery/signature.png";
import about_shape from "../assets/images/gallery/about-shape.png";
import { useEffect, useState } from "react";

const About = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen size
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check screen size on initial render
    checkScreenSize();

    // Add event listener to update on window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <>
      <section className="about-area section-padding30 position-relative">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 col-xl-6 col-md-6 col-sm-12 "
              data-aos={!isMobile ? "zoom-in-right" : ""}
              data-aos-delay={!isMobile ? "100" : ""}
            >
              {/* <!-- about-img --> */}
              <div className="about-img ">
                <img src={about} alt="" className="img-fluid" />
              </div>
            </div>
            <div
              className="col-lg-6 col-xl-6 col-md-6 col-sm-12 "
              data-aos={!isMobile ? "zoom-in-left" : ""}
              data-aos-delay={!isMobile ? "100" : ""}
            >
              <div className="about-caption">
                {/* <!-- Section Tittle --> */}
                <div className="section-tittle section-tittle3 mb-35">
                  <span>About Our company</span>
                  <h2>33 Years Of Experience </h2>
                </div>
                <p className="mb-30 pera-bottom">
                Welcome to Indulgence, where beauty and excellence have been our passion for over 33 years. As a premier destination for women seeking top-tier salon services, we have built a reputation for delivering exceptional hair, skin, and nail care tailored to each client's unique style and needs.
                </p>
                <p className="pera-top mb-50">
                Join us and experience the difference that years of passion, skill, and dedication can make. Your beauty is our legacy.
                </p>
                {/* <img src={signature} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- About Shape --> */}
        <div className="about-shape">
          <img src={about_shape} alt="" />
        </div>
      </section>
    </>
  );
};

export default About;
