import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./assets/css/style.css";
import "./assets/css/animate.min.css";
import "./assets/css/animated-headline.css";
import  { Toaster } from 'react-hot-toast';
import App from './App';
import reportWebVitals from './reportWebVitals';

//Global Window Object
window.helicore = window.helicore || {};
window.cn = function (o) { return "undefined" === typeof o || null === o || "" === o.toString().trim() };
window.cb = function (o) { if (o === 'true') { return true } else { return false } };


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <Toaster  position="top-right"/>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
