import { Fragment, useEffect, useState } from "react";
import axiosInstace from "../../config/axiosInstance";
import { GET_CATEGORY, GET_SERVICE } from "../../config/apiUrl";
import Loader from "../../components/Loader";

const Service = () => {
  document.title = "Service | Indulgence";

  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [serviceName, setServicename] = useState("");

  const [serviceData, setServiceData] = useState([]);
  const [category, setCategoty] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);

  useEffect(() => {
    // Function to check screen size
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check screen size on initial render
    checkScreenSize();

    // Add event listener to update on window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    getCategoty();
  }, []);

  const getCategoty = async () => {
    try {
      setPageLoad(true);
      const response = await axiosInstace.get(GET_CATEGORY);
      if (response) {
        setActiveTab(response?.data?.payload?.results[0]._id);
        setServicename(response?.data?.payload?.results[0].name);
        setCategoty(response?.data?.payload?.results);
        getServiceDetails(response?.data?.payload?.results[0]._id);
        setPageLoad(false);
      }
    } catch (error) {
      setCategoty([]);
    }
  };

  const getServiceDetails = async (id, name) => {
    try {
      setPageLoad(true);

      setActiveTab(id);
      setServicename(name);
      const response = await axiosInstace.get(
        GET_SERVICE + "?category_id=" + id
      );
      if (response) {
        setPageLoad(false);

        setServiceData(response?.data?.payload);
      }
    } catch (error) {
      setServiceData([]);
    }
  };

  return (
    <>
      <div className="slider-area2 services-tab">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-3 col-12 col-sm-12 px-0">
                <div className="hero-cap hero-cap2 px-0">
                  <h2 className="text-center">Our Services</h2>
                </div>{" "}
              </div>
              <div className="col-xl-9 col-lg-9 col-md-9 col-12 col-sm-12 right-div">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {category &&
                    category.length > 0 &&
                    category.map((item, index) => (
                      <button
                        key={index}
                        className={`nav-link ${
                          activeTab === item._id ? "active" : ""
                        }`}
                        id={`${item._id}-tab`}
                        data-toggle="tab"
                        data-target={`#${item._id}`}
                        type="button"
                        role="tab"
                        aria-controls={item._id}
                        aria-selected={activeTab === item._id}
                        onClick={() => getServiceDetails(item?._id, item?.name)}
                      >
                        {item?.name}
                      </button>
                    ))}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>

      <section className="service-area section-padding30    ">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-11 col-sm-11">
              <div className="section-tittle text-center mb-90">
                <h2>
                  Our {serviceName !== "" && serviceName} services that we
                  offering to you
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            {serviceData && serviceData.length > 0 ? (
              serviceData.map((item, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade ${
                        activeTab === item.category_id ? "show active" : ""
                      }`}
                      id={item.category_id}
                      role="tabpanel"
                      aria-labelledby={`${item.category_id}-tab`}
                    >
                      <div className="services-caption service-tab text-center mb-30">
                        {/* <div className="service-icon">
                          <i>
                            <img
                              src={item.image}
                              className="img-fluid"
                              alt="service"
                            />
                          </i>
                        </div> */}
                        <div className="service-cap">
                          <h2 className="mb-4">
                            {item.service_name}
                          </h2>
                          <h3 className="mb-4">£ {item.service_price}</h3>
                          <p>{item?.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12">
                <h1 className="text-center">Coming Soon ...</h1>
              </div>
            )}
          </div>
        </div>
      </section>

      {pageLoad === true && <Loader />}
    </>
  );
};

export default Service;
