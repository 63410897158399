import { useEffect, useState } from "react";

import gallary1 from "../../assets/images/gallary-1.jpeg";
import gallary2 from "../../assets/images/Service/nail-polish.jpg";
import gallary3 from "../../assets/images/gallery-6.jpeg";
import gallary4 from "../../assets/images/Service/gallary-new.jpg";
import gallary5 from "../../assets/images/Service/acrylic.jpg";
import gallary6 from "../../assets/images/Service/beauty-hero.png";
import { GET_PORTFOLIO } from "../../config/apiUrl";
import axiosInstance from "../../config/axiosInstance";
import Loader from "../../components/Loader";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  document.title = "Portfolio | Indulgence";

  const [isMobile, setIsMobile] = useState(false);
  const [portfoli, setPortfolio] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);

  useEffect(() => {
    // Function to check screen size
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check screen size on initial render
    checkScreenSize();

    // Add event listener to update on window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  useEffect(() => {
    getPortfolio();
  }, []);

  const getPortfolio = async () => {
    try {
      setPageLoad(true);
      const response = await axiosInstance.get(GET_PORTFOLIO);
      if (response) {
        setPortfolio(response?.data?.payload);
        setPageLoad(false);
      }
    } catch (error) {
      setPortfolio([]);
    }
  };

  return (
    <>
      <div className="slider-area2">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 pt-70 text-center">
                  <h2>Portfolio</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-area section-padding30">
        <div className="container">
          <div className="row">
            {portfoli &&
              portfoli.length > 0 &&
              portfoli.map((item, index) => (
                <>
                  {index % 2 === 0 ? (
                    <>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div
                          className="box snake mb-30"
                          data-aos={!isMobile ? "fade-right" : ""}
                          data-aos-delay={!isMobile ? "300" : ""}
                        >
                          <div
                            className="gallery-img"
                            style={{ backgroundImage: `url(${item?.url})` }}
                          ></div>
                          <div className="overlay"></div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="col-lg-8 col-md-6 col-sm-6"
                        data-aos={!isMobile ? "fade-left" : ""}
                        data-aos-delay={!isMobile ? "300" : ""}
                      >
                        <div className="box snake mb-30">
                          <div
                            className="gallery-img"
                            style={{ backgroundImage: `url(${item?.url})` }}
                          ></div>
                          <div className="overlay"></div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            {/* 

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div
                className="box snake mb-30"
                data-aos={!isMobile ? "fade-right" : ""}
                data-aos-delay={!isMobile ? "300" : ""}
              >
                <div
                  className="gallery-img"
                  style={{ backgroundImage: `url(${gallary1})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div>
            <div
              className="col-lg-8 col-md-6 col-sm-6"
              data-aos={!isMobile ? "fade-left" : ""}
              data-aos-delay={!isMobile ? "300" : ""}
            >
              <div className="box snake mb-30">
                <div
                  className="gallery-img"
                  style={{ backgroundImage: `url(${gallary2})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div>
            <div
              className="col-lg-8 col-md-6 col-sm-6"
              data-aos={!isMobile ? "fade-right" : ""}
              data-aos-delay={!isMobile ? "300" : ""}
            >
              <div className="box snake mb-30">
                <div
                  className="gallery-img"
                  style={{ backgroundImage: `url(${gallary3})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos={!isMobile ? "fade-left" : ""}
              data-aos-delay={!isMobile ? "300" : ""}
            >
              <div className="box snake mb-30">
                <div
                  className="gallery-img "
                  style={{ backgroundImage: `url(${gallary4})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div
                className="box snake mb-30"
                data-aos={!isMobile ? "fade-right" : ""}
                data-aos-delay={!isMobile ? "300" : ""}
              >
                <div
                  className="gallery-img"
                  style={{ backgroundImage: `url(${gallary5})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div>
            <div
              className="col-lg-8 col-md-6 col-sm-6"
              data-aos={!isMobile ? "fade-left" : ""}
              data-aos-delay={!isMobile ? "300" : ""}
            >
              <div className="box snake mb-30">
                <div
                  className="gallery-img"
                  style={{ backgroundImage: `url(${gallary6})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {pageLoad === true && <Loader />}
    </>
  );
};

export default Portfolio;
