import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axiosInstace from "../../config/axiosInstance";
import { SignUpFormValidation } from "../../config/setting";
import { SIGNUP } from "../../config/apiUrl";

const SignUp = () => {
  useEffect(()=>{
    window.scrollTo({top:0  , behavior:'smooth'})
  },[])
  document.title = "Sign Up | Indulgence";


  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (value, name) => {
    setUserData({ ...userData, [name]: value });
  };

  const SignUpSubmit = async () => {
    const { errors, isError } = SignUpFormValidation(userData);
    setErrors(errors);
    if (!isError) {
      try {
        setLoader(true);
        const response = await axiosInstace.post(SIGNUP, userData);
        if (response) {
          setLoader(false);
          setUserData({});
          toast.success(response.data.message);
          navigate("/login");
        }
      } catch (error) {
        setLoader(false);

        toast.error(error.response.data.message);
      }
    }
  };
  return (
    <>
      <div className="slider-area3">
        <div className="background-overlay-area3"></div>
        <div className="slider-height3 d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-10">
                <div className="form-main-div">
                  <div className="form-inputs-div">
                    <div className="row">
                      <div className="col-xl-12 pl-0 mb-5">
                        <div className="hero-cap hero-cap2 text-center">
                          <h2>Sign Up</h2>
                        </div>
                      </div>
                      <div className="col-xl-6 pl-0">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputFirstName"
                            aria-describedby="firstNameHelp"
                            placeholder="First Name"
                          autoComplete="off"

                            value={userData?.first_name}
                            onChange={(e) =>
                              handleChange(e.target.value, "first_name")
                            }
                          />
                          <p className="red-color">{error?.first_name}</p>
                        </div>
                      </div>
                      <div className="col-xl-6 pl-0">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputLastName"
                            placeholder="Last Name"
                          autoComplete="off"

                            value={userData?.last_name}
                            onChange={(e) =>
                              handleChange(e.target.value, "last_name")
                            }
                          />
                          <p className="red-color">{error?.last_name}</p>
                        </div>
                      </div>
                      <div className="col-xl-6 pl-0">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                          autoComplete="off"
                            placeholder="Enter Email"
                            value={userData?.email}
                            onChange={(e) =>
                              handleChange(e.target.value, "email")
                            }
                          />
                          <p className="red-color">{error?.email}</p>
                        </div>
                      </div>
                      <div className="col-xl-6 pl-0">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            id="exampleFormControlNumber"
                            placeholder="Enter Phone Number"
                          autoComplete="off"
                            value={userData?.phone_no}
                            onChange={(e) =>
                              handleChange(e.target.value, "phone")
                            }
                          />
                          <p className="red-color">{error?.phone}</p>
                        </div>
                      </div>
                      <div className="col-xl-6 pl-0">
                        <div className="form-group">
                          <input
                            type="tetx"
                            className="form-control"
                            id="exampleFormControlpass"
                            placeholder="Enter Password"
                          autoComplete="off"
                            value={userData?.password}
                            onChange={(e) =>
                              handleChange(e.target.value, "password")
                            }
                          />
                          <p className="red-color">{error?.password}</p>
                        </div>
                      </div>
                      <div className="col-xl-6 pl-0">
                        <div className="form-group">
                          <input
                            type="tetx"
                            className="form-control"
                            id="exampleFormControlpass1"
                            placeholder="Enter Confirm Password"
                          autoComplete="off"
                            value={userData?.confirm_password}
                            onChange={(e) =>
                              handleChange(e.target.value, "confirm_password")
                            }
                          />
                          <p className="red-color">{error?.confirm_password}</p>
                        </div>
                      </div>
                      <div className="col-xl-12 d-flex justify-content-center align-items-center mb-4">
                        <div className="btn header-btn" onClick={SignUpSubmit}>
                          {loader == true ? (
                            <>
                              <i className="fa fa-circle-o-notch fa-spin"></i>
                            </>
                          ) : (
                            "Submit"
                          )}
                        </div>
                      </div>
                      <div className="col-xl-12 d-flex justify-content-center">
                        <div className="ac-login-txt text-center">
                          Already have an account ?{" "}
                          <NavLink className="inner-txt" to="/login">
                            Login
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp;
