import { useLocation } from "react-router-dom";
import gallary1 from "../assets/images/gallary-1.jpeg";
import gallary2 from "../assets/images/Service/nail-polish.jpg";
import gallary3 from "../assets/images/gallery-6.jpeg";
import gallary4 from "../assets/images/Service/gallary-new.jpg";
import { useEffect, useState } from "react";

const Gallary = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen size
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check screen size on initial render
    checkScreenSize();

    // Add event listener to update on window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  const location = useLocation();
  return (
    <>
      <div className="gallery-area section-padding30">
        <div className="container">
          {/* <!-- Section Tittle --> */}
          {location.pathname !== "/portfolio" ? (
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-md-9 col-sm-10">
                <div
                  className="section-tittle text-center mb-100"
                  data-aos={!isMobile ? "fade-left" : ""}
                  data-aos-delay={!isMobile ? "300" : ""}
                >
                  <span>our image gellary</span>
                  <h2>some images from our shop</h2>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div
                className="box snake mb-30"
                data-aos={!isMobile ? "fade-right" : ""}
                data-aos-delay={!isMobile ? "300" : ""}
              >
                <div
                  className="gallery-img"
                  style={{ backgroundImage: `url(${gallary1})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div>
            <div
              className="col-lg-8 col-md-6 col-sm-6"
              data-aos={!isMobile ? "fade-left" : ""}
              data-aos-delay={!isMobile ? "300" : ""}
            >
              <div className="box snake mb-30">
                <div
                  className="gallery-img"
                  style={{ backgroundImage: `url(${gallary2})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div>
            <div
              className="col-lg-8 col-md-6 col-sm-6"
              data-aos={!isMobile ? "fade-right" : ""}
              data-aos-delay={!isMobile ? "300" : ""}
            >
              <div className="box snake mb-30">
                <div
                  className="gallery-img"
                  style={{ backgroundImage: `url(${gallary3})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos={!isMobile ? "fade-left" : ""}
              data-aos-delay={!isMobile ? "300" : ""}
            >
              <div className="box snake mb-30">
                <div
                  className="gallery-img "
                  style={{ backgroundImage: `url(${gallary4})` }}
                ></div>
                <div className="overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallary;
