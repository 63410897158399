import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "../config/axiosInstance";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  BOOK_APPOINTMENT,
  GET_EMPLOYEE,
  GET_SERVICE,
  GET_SLOT,
} from "../config/apiUrl";
import {
  AppiontmentFormValidation,
  convertServerDate,
} from "../config/setting";
import Select from "react-select";

const Appiontment = () => {
  document.title = "Book Appointment | Indulgence";

  const [btnLoader, setBtnLoader] = useState(false);
  const [bookLoader, setBookLoader] = useState({});
  const [hasChecked, setHasChecked] = useState(false);
  const [date, setDate] = useState(new Date());
  const [service, setService] = useState();
  const [employee, setEmployee] = useState();
  const [employeeList, setEmployeeList] = useState([]);
  const [error, setErrors] = useState({});
  const [availableSlotsList, setAvailableSlotsList] = useState([]);

  const [serviceList, setServiceList] = useState([]);

  const token = localStorage.getItem("jwtToken");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!window.cn(token)) {
      getServiceDetails();
      getEmployeeList();
    }
  }, []);

  const getServiceDetails = async () => {
    try {
      const response = await axios.get(GET_SERVICE);

      if (response) {
        const serviceData = response?.data?.payload.map((item) => ({
          label: item.service_name,
          value: item._id,
        }));
        setServiceList(serviceData);
      }
    } catch (error) {
      setServiceList([]);
    }
  };

  const getEmployeeList = async () => {
    try {
      const response = await axios.get(GET_EMPLOYEE);

      if (response) {
        const employeeData = response?.data?.payload.results.map((item) => ({
          label: item.first_name + " " + item.last_name,
          value: item._id,
        }));
        setEmployeeList(employeeData);
      }
    } catch (error) {
      setEmployeeList([]);
    }
  };
  const getSlotList = async () => {
    const { errors, isError } = AppiontmentFormValidation(
      date,
      service,
      employee
    );
    setErrors(errors);
    if (!isError) {
      try {
        setBtnLoader(true);
        const response = await axios.get(
          GET_SLOT +
            "?date=" +
            convertServerDate(date) +
            "&employId=" +
            employee.value
        );
        if (response) {
          const { payload } = response?.data;
          setAvailableSlotsList(payload?.slots);
          setBtnLoader(false);
        }
        setHasChecked(true);
      } catch (error) {
        setBtnLoader(false);
      }
    }
  };

  // Get Availble Time Slots Data
  const getAvailableSlotsData = () => {
    if (hasChecked) {
      if (!window.cn(availableSlotsList) && availableSlotsList.length > 0) {
        return availableSlotsList.map(function (availableSlots, index) {
          return (
            <div className="book_slot " key={index}>
              <ul>
                <li>
                  <span>Time</span>
                  <p>{availableSlots.time}</p>
                </li>
                <li>
                  <span>Status</span>
                  <p>
                    {availableSlots.isBooked === true ? (
                      <span
                        className="badge badge-danger"
                        style={{ color: "#FFF" }}
                      >
                        Full
                      </span>
                    ) : (
                      <span
                        className="badge badge-success"
                        style={{ color: "#FFF" }}
                      >
                        Available
                      </span>
                    )}
                  </p>
                </li>
              </ul>

              <button
                type="button"
                className="btn-booking"
                onClick={() => bookAppointment(availableSlots._id)}
                disabled={availableSlots.isBooked === true ? true : false}
              >
                {bookLoader[availableSlots._id] ? (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                ) : (
                  <>
                    <span>Book Appointment</span>
                  </>
                )}
              </button>
            </div>
          );
        });
      } else {
        return <h1 className="no-data-found mt-5">No Available Appointment</h1>;
      }
    }
  };

  const bookAppointment = async (id) => {
    if (!window.cn(token)) {
      try {
        setBookLoader((prevState) => ({
          ...prevState,
          [id]: true,
        }));
        const requestData = {
          slotId: id,
          serviceId: service.value,
          employId: employee.value,
        };
        const response = await axios.post(
          BOOK_APPOINTMENT,
          requestData
        );
        if (response) {
          setBookLoader((prevState) => ({
            ...prevState,
            [id]: true,
          }));
          setService("");
          setEmployee("");
          getSlotList();

          toast.success(response.data.message);
          navigate("/appointment-list");
        }
      } catch (error) {
        if (error.response) {
          setBookLoader((prevState) => ({
            ...prevState,
            [id]: true,
          }));
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error("Oops! You must be logged in to book an appointment.");
    }
  };

  return (
    <>
      <div className="slider-area3">
        <div className="background-overlay-area3"></div>
        <div className="slider-height3 d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-12">
                <div className="form-main-div">
                  <div className="form-inputs-div">
                    <div className="row">
                      <div className="col-xl-12 mb-5">
                        <div className="hero-cap hero-cap2 text-center">
                          <h2>Book Appointment</h2>
                        </div>
                      </div>
                      {!window.cn(token) ? (
                        <>
                          <div className="col-xl-6 p-0 pr-4 mb-5 service-r-div">
                            <label style={{ color: "#fff" }}>
                              Select Service
                            </label>
                            <Select
                              placeholder="Select Service"
                              options={serviceList}
                              onChange={(value) => setService(value)}
                              value={service}
                              className="mb-2 react-select-control"
                            />
                            <p className="red-color">{error?.service}</p>
                          </div>
                          <div className="col-xl-6 sm-pl-0 p-0 pl-4 mb-5 employee-l-div">
                            <label style={{ color: "#fff" }}>
                              Select Employee
                            </label>
                            <Select
                              placeholder="Select Employee"
                              options={employeeList}
                              onChange={(value) => setEmployee(value)}
                              value={employee}
                              className="mb-2 react-select-control"
                            />
                            <p className="red-color">{error?.employee}</p>
                          </div>
                          <div className="col-xl-6 col-12 col-sm-12  pl-0 mb-5 calender-input">
                            <div className="calender react-calender">
                              <Calendar
                                onChange={setDate}
                                value={date}
                                minDate={new Date()}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-xl-6 col-md-12 col-12 col-sm-12  mb-5">
                            <div
                              className="btn header-btn"
                              style={{ zIndex: 0 }}
                              type="button"
                              onClick={() => getSlotList()}
                            >
                              {btnLoader == true ? (
                                <>
                                  <i className="fa fa-circle-o-notch fa-spin"></i>
                                </>
                              ) : (
                                "Check Appointments"
                              )}
                            </div>
                            {getAvailableSlotsData()}
                          </div>
                        </>
                      ) : (
                        <div className="col-xl-12 col-12 col-sm-12 d-flex flex-column justify-content-center align-items-center">
                          <h1 className="main-message mb-5">
                            Oops! You must be logged in to book an appointment.
                          </h1>

                          <NavLink to="/login">
                          <button
                            className="btn header-btn"
                            type="button"
                          >Login</button>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Appiontment;
