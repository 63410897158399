import About from "../../components/About";
import { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },[]);
  document.title = "About | Indulgence";

  return (
    <>
      <div className="slider-area2">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 pt-70 text-center">
                  <h2>About US</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <About />
      <section className="home-blog-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-10 col-md-11 col-sm-11">
              <div className="section-tittle text-center mb-100">
                <h2 className="mb-5">About Us</h2>
                <span>
                Our journey began with a simple mission: to empower women by enhancing their natural beauty through expert techniques and personalized care. Over the decades, we've stayed ahead of the latest trends and innovations in the beauty industry, ensuring that our clients receive the highest quality services in a warm and welcoming environment.
                </span>

                <span>
                At INDULGENCE, our experienced team of stylists and beauticians are dedicated to making every visit a luxurious experience. Whether you're here for a fresh haircut, a rejuvenating facial, or a complete makeover, our goal is to leave you feeling confident and radiant.
                </span>
                <span>
                Our commitment to excellence has earned us the trust and loyalty of countless clients, many of whom have been with us since the beginning. With decades of experience, we pride ourselves on understanding the unique needs of every woman who walks through our doors.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default AboutUs;
