import { Fragment, useEffect, useState } from "react";
import Service1 from "../../src/assets/images/Service/nail-service.png";
import Service2 from "../../src/assets/images/Service/beauty-service.png";
import Service3 from "../../src/assets/images/Service/haircut-service.png";
import { NavLink } from "react-router-dom";
import axiosInstace from "../config/axiosInstance";
import { GET_CATEGORY } from "../config/apiUrl";
import Loader from "./Loader";

const Services = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [category, setCategoty] = useState([]);
  const [pageLoad,setPageLoad] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Function to check screen size
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check screen size on initial render
    checkScreenSize();

    // Add event listener to update on window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      setPageLoad(true);
      const response = await axiosInstace.get(GET_CATEGORY);
      if (response) {

        setCategoty(response?.data?.payload?.results);
      setPageLoad(false);

      }
    } catch (error) {
      setCategoty([]);
      setPageLoad(false);

    }
  };

  return (
    <>
      <section className="service-area home pb-170">
        <div className="container">
          {/* <!-- Section Tittle --> */}
          <div className="row d-flex justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-11 col-sm-11">
              <div
                className="section-tittle text-center mb-90"
                data-aos={!isMobile ? "fade-left" : ""}
                data-aos-delay={!isMobile ? "300" : ""}
              >
                <span>Professional Services</span>
                <h2>Our Best services that we offering to you</h2>
              </div>
            </div>
          </div>
          {/* <!-- Section caption --> */}
          <div className="row">
            {category &&
              category.length > 0 &&
              category.map((item, index) => (
                <Fragment key={index}>
                  <div
                    className="col-xl-4 col-lg-4 col-md-6"
                    data-aos={!isMobile ? "flip-left" : ""}
                    data-aos-delay={!isMobile ? "800" : ""}
                  >
                    <NavLink to="/service">
                      <div className="services-caption text-center mb-30">
                        <div className="service-icon">
                          <i>
                            <img
                              src={
                                index === 1
                                  ? Service2
                                  : index === 2
                                  ? Service3
                                  : Service1
                              }
                              className="img-fluid"
                              alt="service"
                            />
                          </i>
                        </div>
                        <div className="service-cap">
                          <h4>{item.name}</h4>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </Fragment>
              ))}
          </div>
        </div>
      </section>

      {pageLoad === true && <Loader />}
    </>
  );
};

export default Services;
