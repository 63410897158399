import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo/mainLogo.png";
import { useEffect, useState } from "react";
import avatar from "../assets/images/gallery/avtar.png";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showBackTop, setShowBackTop] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll < 400) {
        setIsSticky(false);
        setShowBackTop(false);
      } else {
        setIsSticky(true);
        setShowBackTop(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : "";

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <header>
        {/* <!--? Header Start --> */}
        <div className="header-area header-transparent pt-20 ">
          <div
            className={
              isSticky
                ? "main-header header-sticky sticky-bar"
                : "main-header header-sticky"
            }
          >
            <div className="container-fluid">
              <div className="menu-main">
                <div className="main-menu">
                  <nav className="navbar navbar-expand-lg ">
                    <div className="container-fluid">
                      <div className="logo ">
                        <NavLink to="/" className="d-flex align-items-center">
                          <img src={logo} alt="logo" className="img-fluid logo" />
                          <h6 className="mb-0 ml-3 header-text">INDULGENCE</h6>
                        </NavLink>
                      </div>
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        {/* <span className="navbar-toggler-icon"></span> */}
                        <i className="fas fa-bars"></i>
                      </button>

                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                      >
                        <ul id="navigation" className="navbar-nav ml-auto">
                          <li className="active">
                            <NavLink to="/">Home</NavLink>
                          </li>
                          <li>
                            <NavLink to="/about">About</NavLink>
                          </li>
                          <li>
                            <NavLink to="/service">Service</NavLink>
                          </li>

                          {/* <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              role="button"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Service
                            </a>
                            <div className="dropdown-menu">
                              <NavLink
                                to="/nail-enhancement"
                                className="dropdown-item"
                              >
                                Nail Enhamcement
                              </NavLink>
                              <NavLink to="/beauty" className="dropdown-item">
                                Beauty
                              </NavLink>
                              <NavLink
                                to="hair-style"
                                className="dropdown-item"
                              >
                                Hair Cut
                              </NavLink>
                            </div>
                          </li> */}

                          <li>
                            <NavLink to="/portfolio">Portfolio</NavLink>
                          </li>

                          <li>
                            <NavLink to="/contact">Contact</NavLink>
                          </li>

                        </ul>

                        {!userData?.first_name ? (
                          <div className="header-right-btn  d-lg-block ml-3">
                            <NavLink to="/login" className="btn header-btn">
                              Login
                            </NavLink>
                          </div>
                        ) : (
                          ""
                        )}

                        {userData?.first_name && (
                          <div className="profile-drp d-flex align-items-center ml-3">
                            <span className="p-name">
                              {userData?.first_name}
                            </span>
                            <li className="nav-item dropdown ">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  src={avatar}
                                  width="40"
                                  height="40"
                                  className="rounded-circle"
                                />
                              </a>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdownMenuLink"
                              >
                                <NavLink
                                  to="/change-password"
                                  role="button"
                                  className="dropdown-item"
                                >
                                  Change Password
                                </NavLink>
                                <NavLink
                                  to="/appointment-list"
                                  role="button"
                                  className="dropdown-item"
                                >
                                  My Appointment
                                </NavLink>
                                <span
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() => logout()}
                                >
                                  Log Out
                                </span>
                              </div>
                            </li>
                          </div>
                        )}
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Header End --> */}
      </header>
    </>
  );
};

export default Header;
