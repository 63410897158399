import {  useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";
import { useState } from "react";
import toast from "react-hot-toast";
import { ChangePasswordValidation } from "../../config/setting";
import { CHANGE_PASSWORD } from "../../config/apiUrl";

const ChangePassword = () => {
  document.title = "Change Password | Indulgence";

  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (value, name) => {
    setUserData({ ...userData, [name]: value });
  };

  const Login = async () => {
    const { errors, isError } = ChangePasswordValidation(userData);
    setErrors(errors);
    if (!isError) {
      try {
        setLoader(true);

        const response = await axiosInstance.post(CHANGE_PASSWORD, userData);
        if (response) {
          setLoader(false);
          navigate("/login");

          toast.success(response.data.message);
        }
      } catch (error) {
        setLoader(false);

        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="slider-area3">
        <div className="background-overlay-area3"></div>
        <div className="slider-height3 d-flex align-items-center">
          <div className="container ">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-8">
                <div className="form-main-div">
                  <div className="row">
                    <div className="col-xl-12 px-0 mb-5">
                      <div className="hero-cap hero-cap2 text-center">
                        <h2>Change Password</h2>
                      </div>
                    </div>

                    <div className="col-xl-12 px-0">
                      <div className="form-group">
                        <input
                          type="tetx"
                          className="form-control"
                          id="exampleFormControlpass"
                          placeholder="Enter Old Password"
                          autoComplete="off"
                          value={userData?.old_password || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "old_password")
                          }
                        />
                        <p className="red-color">{error?.old_password}</p>
                      </div>
                    </div>
                    <div className="col-xl-12 px-0">
                      <div className="form-group">
                        <input
                          type="tetx"
                          className="form-control"
                          id="exampleFormControlpass"
                          placeholder="Enter New Password"
                          autoComplete="off"
                          value={userData?.new_password || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "new_password")
                          }
                        />
                        <p className="red-color">{error?.new_password}</p>
                      </div>
                    </div>
                    <div className="col-xl-12 px-0">
                      <div className="form-group">
                        <input
                          type="tetx"
                          className="form-control"
                          id="exampleFormControlpass"
                          placeholder="Enter Confirm Password"
                          autoComplete="off"
                          value={userData?.confirm_password || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "confirm_password")
                          }
                        />
                        <p className="red-color">{error?.confirm_password}</p>
                      </div>
                    </div>

                    <div className="col-xl-12 d-flex justify-content-center mb-4">
                      <div className="btn header-btn" onClick={() => Login()}>
                        {loader == true ? (
                          <>
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                          </>
                        ) : (
                          "Submit"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
