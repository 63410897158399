import About from "../../components/About";
import Gallary from "../../components/Gallary";
import Pricing from "../../components/Pricing";
import Services from "../../components/Services";
import HeroSlider from "./Slider";
import Testimonial from "../../components/Testimonial";
import { useEffect } from "react";
import FeedBack from "./FeedBack";

const Home = () => {
  document.title = "Home | Indulgence";

  useEffect(()=>{
    window.scrollTo({top:0  , behavior:'smooth'})
  },[])
  
  return (
    <>
        <HeroSlider />
        <About />
        <Services />
        <Pricing />
        <Gallary />
        <Testimonial />
        <FeedBack />
    </>
  );
};

export default Home;
