import team1 from "../../src/assets/images/gallery/team1.png";
import team2 from "../../src/assets/images/gallery/team2.png";
import team3 from "../../src/assets/images/gallery/team3.png";
import Service1 from "../../src/assets/images/Service/nail-service.png";
import Service2 from "../../src/assets/images/Service/beauty-service.png";
import Service3 from "../../src/assets/images/Service/haircut-service.png";
import Gelex from "../../src/assets/images/Service/gelex-service.webp";
import Artifical from "../../src/assets/images/Service/artificaial-service.webp";
import Polish from "../../src/assets/images/Service/nail-polish.jpg";
import Additional from "../../src/assets/images/Service/additional.jpg";
import Acrylic from "../../src/assets/images/Service/acrylic.jpg";
import Classic from "../../src/assets/images/Service/classical.jpg";
import Microneedling from "../../src/assets/images/Service/microneedling.jpg";
import Brightening from "../../src/assets/images/Service/brightening.jpg";
import Hydrating from "../../src/assets/images/Service/hydrating.jpg";
import Eyebro from "../../src/assets/images/Service/eye-bro-shape.jpg";
import Eyelash from "../../src/assets/images/Service/eyebro-shape2.jpg";

export const ServicesList = [ 
  { value: 'Nail Enhancement', label: 'Nail ' },
  { value: 'Beauty', label: 'Beauty' },
  { value: 'Hair Style', label: 'Hair Style' },
]

export const teamActive = {
  dots: true,
  infinite: true,
  speed: 300,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      teamActive: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      teamActive: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      teamActive: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const cutDetails = {
  dots: true,
  infinite: true,
  speed: 300,
  arrows: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

//Home Page Details Start
//Home-Service Section
export const HomeService = [
  {
    id: 1,
    image : Service1,
    title: "NAIL ENHANCEMENT",
    description:
      "Expert nail care that pampers, perfects, and enhances the natural beauty of your hands and feet.",
      link : "/nail-enhancement",
  },
  {
    id: 2,
    image : Service2,
    title: "Beauty",
    description:
      "Personalized beauty care that revitalizes your skin, leaving you glowing and refreshed.",
      link : "/beauty",

  },
  {
    id: 3,
    image : Service3,
    title: "Stylish Hair Cut",
    description:
      "Coming Soon...",
      link : "/hair-cut",

  },
];

//Team Slider Section
export const TeamSlider = [ 
    {
        id :1 ,
        image : team1,
        name : "Guy C. Pulido bks",
        sub_title : "Master Barber",
    },
    {
        id :2 ,
        image : team2,
        name : "Steve L. Nolan",
        sub_title : "Color Expart",
    },
    {
        id :3 ,
        image : team3,
        name : "Edgar P. Mathis",
        sub_title : "Master Barber",
    },
    {
        id :4 ,
        image : team2,
        name : "Edgar P. Mathis",
        sub_title : "Master Barber",
    },
]

//Testimonial Section
export const HomeTestimonial = [
    {
        id : 1,
        name : "Indulgence",
        description : "Hear from our delighted clients who rave about their stunning transformations and exceptional service. Their testimonials are a true reflection of our commitment to beauty and customer satisfaction."
    },
    {
        id : 2,
        name : "Indulgence",
        description : "Hear from our delighted clients who rave about their stunning transformations and exceptional service. Their testimonials are a true reflection of our commitment to beauty and customer satisfaction.."
    },
    {
        id : 3,
        name : "Indulgence",
        description : "Hear from our delighted clients who rave about their stunning transformations and exceptional service. Their testimonials are a true reflection of our commitment to beauty and customer satisfaction."
    },
]

//Service Page
export const ServicePage = [
  {
    id: 1,
    image : Service3,
    title: "Stylish Hair Cut",
    description:
      "Sorem spsum dolor sit amsectetur adipisclit, seddo eiusmod tempor incididunt ut laborea.",
  },
  {
    id: 2,
    title: "Body Massege",
    description:
      "Sorem spsum dolor sit amsectetur adipisclit, seddo eiusmod tempor incididunt ut laborea.",
  },
  {
    id: 3,
    title: "Breard Style",
    description:
      "Sorem spsum dolor sit amsectetur adipisclit, seddo eiusmod tempor incididunt ut laborea.",
  },
  {
    id: 4,
    title: "Stylish Hair Cut",
    description:
      "Sorem spsum dolor sit amsectetur adipisclit, seddo eiusmod tempor incididunt ut laborea.",
  },
  {
    id: 5,
    title: "Body Massege",
    description:
      "Sorem spsum dolor sit amsectetur adipisclit, seddo eiusmod tempor incididunt ut laborea.",
  },
  {
    id: 6,
    title: "Breard Style",
    description:
      "Sorem spsum dolor sit amsectetur adipisclit, seddo eiusmod tempor incididunt ut laborea.",
  },
];


export const NailEnhancement = [
  {
    id: 1,
    image : Gelex,
    title: "GELEX",
    price:"(£) 10",
    description:
      "Lightweight, flexible nails that offer a flawless, natural look with long-lasting wear.",
  },
  {
    id: 2,
    image : Polish,
    title: "Polish",
    price:"(£) 10",
    description:
      "Classic nail polish application for a vibrant, smooth finish.",
  },
  {
    id: 3,
    image : Artifical,
    title: "Artifical Services",
    price:"(£) 10",
    description:
    "Durable and stylish nail enhancements for added length and strength.",
  },
  {
    id: 4,
    image : Acrylic,
    title: "Acrylic Nails",
    price:"(£) 10",
    description:
    "Strong, sculpted nails that provide lasting durability and customizable designs.",
  },
  {
    id: 5,
    image : Additional,
    title: "Additional Services",
    price:"(£) 10",
    description:
    "Unique, bespoke nail art tailored to your individual style and preferences.",
  },
];

export const BeautyService = [
  {
    id: 1,
    image : Classic,
    title: "Classic Facial",
    description:
      "A rejuvenating facial treatment that cleanses, exfoliates, and revitalizes your skin for a radiant glow.",
  },
  {
    id: 2,
    image : Microneedling,
    title: "Microneedling Facial",
    description:
      "Advanced skin rejuvenation therapy that boosts collagen production for a smoother, firmer complexion.",
  },
  {
    id: 3,
    image : Brightening,
    title: "Brightening Facial",
    description:
    "Targeted treatments that illuminate your skin, reducing dullness and evening out your complexion.",
  },
  {
    id: 4,
    image : Hydrating,
    title: "Hydrating Facial",
    description:
    "Deeply moisturizing treatments that restore your skin's hydration, leaving it soft and supple.",
  },
  {
    id: 5,
    image : Eyebro,
    title: "Eye Brow Shape",
    description:
    "Expert shaping and grooming to enhance the natural beauty and definition of your eyebrows.",
  },
  {
    id: 6,
    image : Eyelash,
    title: "Classic Eye Lash",
    description:
    "Luxurious lash treatments that add volume, length, and curl for a captivating look.",
  },
];