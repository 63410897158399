import {  useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FORGOT_PASSWORD } from "../../config/apiUrl";
import {
  ResetPasswordValidation,
} from "../../config/setting";

const ResetPassword = () => {
  document.title = "Forgot Password | Indulgence";

  const [userData, setUserData] = useState({});
  const [error, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const [token, setToken] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get("token");

    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, []);


  const navigate = useNavigate();

  const handleChange = (value, name) => {
    setUserData({ ...userData, [name]: value });
  };

  const ForgotPassword = async () => {
    const { errors, isError } = ResetPasswordValidation(userData);
    setErrors(errors);
    if (!isError) {
      try {
        setLoader(true);
        const requestData = {
          email: userData.email,
          password: userData.password,
          token: token,
        };
        const response = await axiosInstance.post(FORGOT_PASSWORD, requestData);
        if (response) {
          setLoader(false);
          navigate("/login");
          toast.success(response.data.message);
        }
      } catch (error) {
        setLoader(false);

        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="slider-area3">
        <div className="background-overlay-area3"></div>
        <div className="slider-height3 d-flex align-items-center">
          <div className="container ">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-7">
                <div className="form-main-div">
                  <div className="row">
                    <div className="col-xl-12 px-0 mb-5">
                      <div className="hero-cap hero-cap2 text-center">
                        <h2>Reset Your Password</h2>
                      </div>
                    </div>
                    <div className="col-xl-12 px-0">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Email"
                          autoComplete="off"
                          value={userData?.email || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "email")
                          }
                        />
                        <p className="red-color">{error?.email}</p>
                      </div>
                    </div>

                    <div className="col-xl-12 px-0">
                      <div className="form-group">
                        <input
                          type="tetx"
                          className="form-control"
                          id="exampleFormControlpass"
                          placeholder="Enter Password"
                          autoComplete="off"
                          value={userData?.password || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "password")
                          }
                        />
                        <p className="red-color">{error?.password}</p>
                      </div>
                    </div>

                    <div className="col-xl-12 d-flex justify-content-center mb-4">
                      <div
                        className="btn header-btn"
                        onClick={() => ForgotPassword()}
                      >
                        {loader == true ? (
                          <>
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                          </>
                        ) : (
                          "Submit"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
