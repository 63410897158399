import { useEffect, useState } from "react";
import axiosInstance from "../../config/axiosInstance";
import { GET_APPOINTMENT_LIST } from "../../config/apiUrl";
import Loader from "../../components/Loader";
import moment from "moment";

const AppointmentList = () => {
  document.title = "Appointment List | Indulgence";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getAppointmentList();
  }, []);

  const [appointmentList, setAppointmentList] = useState([]);

  const [pageLoad, setPageLoad] = useState(false);

  const getAppointmentList = async () => {
    setPageLoad(true);
    try {
      const response = await axiosInstance.get(GET_APPOINTMENT_LIST);
      if (response) {
    setPageLoad(false);
        setAppointmentList(response.data.payload.results);
      }
    } catch (error) {
      setAppointmentList([]);
    }
  };

  return (
    <>
      <div className="slider-area2">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 pt-70 text-center">
                  <h2>Appointment List</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-area section-padding10 position-relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">
              <table className="table table-striped  table-appoitment">
                <thead>
                  <tr>
                    <th scope="col">Index</th>
                    <th scope="col">Service Name</th>
                    <th scope="col">Employee Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {appointmentList && appointmentList.length > 0 ? (
                    appointmentList.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.service_name}</td>
                        <td>{item?.employ_name}</td>
                        <td>{moment(item?.date).format("DD-MM-YYYY")}</td>
                        <td>{item?.time}</td>
                        <td><span className={item.status === "pending" ? "badge badge-warning" : item.status ==="cancelled" ? "badge badge-danger" : "badge badge-success"}>{item?.status}</span></td>
                      </tr>
                    ))
                  ) : (
                    <h1 className="no-data">No Data Found</h1>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      {pageLoad === true && <Loader />}

    </>
  );
};

export default AppointmentList;
