import logo from "./logo.svg";
import "./App.css";
import Header from "./layout/header";
import Home from "./pages/home/Home";
import { useEffect } from "react";
import Aos from "aos";
//Include Css
import "aos/dist/aos.css";
import { BrowserRouter } from "react-router-dom";
import Layout from "./layout/layout";

function App() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </>
  );
}

export default App;
