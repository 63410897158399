const FeedBack = () => {
  return (
    <>
      <section className="home-blog-area section-padding30">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-10 col-sm-12">
              <div className="section-tittle text-center mb-90">
                
                <h2>Send us your feedback!</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8 col-12 col-sm-12">
              <div className="form-group">
                <label>Give Your FeedBack</label>
                <textarea
                  className="form-control w-100"
                  name="message"
                  id="message"
                  cols="30"
                  rows="9"
                  placeholder=" Enter Message"
                ></textarea>
              </div>
              <div className="form-group mt-3">
              <button type="submit" className="button button-contactForm boxed-btn">
                Send
              </button>
            </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default FeedBack;
