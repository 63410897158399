
export const LOGIN = "/auth/login";
export const SIGNUP = "/auth/sign-up";
export const CHANGE_PASSWORD = "/auth/change-password";
export const SENT_FORGOT_PASSWORD = "/auth/send-forgot-mail";
export const FORGOT_PASSWORD = "/auth/forgot-password";

export const GET_SERVICE = "/user/services";
export const GET_EMPLOYEE = "/user/employ";
export const GET_CATEGORY = "/user/category";
export const GET_PORTFOLIO = "/user/portfolio";
export const GET_SLOT = "/user/slots";
export const BOOK_APPOINTMENT = "/user/book-appointment";
export const GET_APPOINTMENT_LIST = "/user/appointment";


