import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import AboutUs from "../pages/about/About";
import Service from "../pages/service/Service";
import Portfolio from "../pages/portfolio/Portfolio";
import SignUp from "../pages/signUp/SignUp";
import Login from "../pages/login/Login";
import Appiontment from "../components/Appiontment";
import ChangePassword from "../pages/changePassword/ChangePassword";
import Contact from "../pages/contact/Contact";
import SendForgotEmail from "../pages/forgotPassword/SendForgotEmail";
import ResetPassword from "../pages/forgotPassword/ResetPassword";
import AppointmentList from "../pages/appointmentList/appointmentList";

const Routers = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<SendForgotEmail  />} />
        <Route path="/forgot-email" element={<ResetPassword  />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/service" element={<Service />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/appointment-list" element={<AppointmentList />} />
        <Route path="/book-appointment" element={<Appiontment />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </Routes>
    </>
  );
};

export default Routers;
