import { useEffect, useState } from "react";

const Contact = () => {
  useEffect(()=>{
    window.scrollTo({top:0  , behavior:'smooth'})
  },[])
  document.title = "Contact Us | Indulgence";


  const [contactData, setContactData] = useState({});

  const handleChange = (value, name) => {
    setContactData({ ...contactData, [name]: value });
  };
  return (
    <>
      <div className="slider-area2">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 pt-70 text-center">
                  <h2>Contact Us</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="contact-title">Get in Touch</h2>
            </div>
            <div className="col-lg-8">
              <form
                className="form-contact contact_form"
                id="contactForm"
                novalidate="novalidate"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="9"
                        placeholder=" Enter Message"
                        autoComplete="off"
                          value={contactData?.message || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "message")
                          }
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="name"
                        id="name"
                        type="text"
                        autoComplete="off"
                        value={contactData?.name || ""}
                        onChange={(e) =>
                          handleChange(e.target.value, "name")
                        }
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        autoComplete="off"
                          value={contactData?.email || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "email")
                          }
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        autoComplete="off"
                          value={contactData?.subject || ""}
                          onChange={(e) =>
                            handleChange(e.target.value, "subject")
                          }
                        placeholder="Enter Subject"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <button
                    type="submit"
                    className="button button-contactForm boxed-btn"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-3 offset-lg-1">
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-home"></i>
                </span>
                <div className="media-body">
                  <h3>Wembley, London, UK</h3>
                  <p>532 High Road, HA9 7BS</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-tablet"></i>
                </span>
                <div className="media-body">
                  <h3>020 8900 1612</h3>
                  <p>Mon to Sat 10 AM to 7 PM</p>
                  <p>Sun 11 AM to 5 PM</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-email"></i>
                </span>
                <div className="media-body">
                  <a href="mailto:indulgencenailbeauty@gmail.com" target="_blank"><h3>indulgencenailbeauty@gmail.com</h3></a>
                  <p>Send us your query anytime!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
