import { NavLink } from "react-router-dom";

const HeroSlider = () => {
    return (
      <>
        {/* <!--? slider Area Start--> */}
        <div className="slider-area position-relative fix">
          <div className="slider-active">
            {/* <!-- Single Slider --> */}
            <div className="single-slider slider-height d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-9 col-md-11 col-sm-10">
                    <div className="hero__caption">
                      <span
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        with Indulgence
                      </span>
                      <h1
                      data-aos="fade-in-up"
                      data-aos-delay="600"
                      >
                        Our Style Makes Your Look Elegance
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          {/* <!-- stroke Text --> */}
          <div className="stock-text">
            <h2>Get Your Own Style</h2>
            <h2>Get Your Own Style</h2>
          </div>
          {/* <!-- Arrow --> */}
          <NavLink to="/book-appointment"> 
          <div className="thumb-content-box">
            <div className="thumb-content">
             <h3>make an appointment now</h3>
              <a href="#">
                {" "}
                <i className="fas fa-long-arrow-alt-right"></i>
              </a>
            </div>
          </div>
          </NavLink>
        </div>
        {/* <!-- slider Area End--> */}
      </>
    );
  };
  
  export default HeroSlider;
  